import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageExpenseLogRoutingModule } from './manage-expense-log-routing.module';
import { AddExpenseLogComponent } from './add-expense-log/add-expense-log.component';
import { HireVehicleFlatComponent } from './hire-vehicle-flat/hire-vehicle-flat.component';
import { HireVehicleMonthlyComponent } from './hire-vehicle-monthly/hire-vehicle-monthly.component';
import { VehicleMaintenanceComponent } from './vehicle-maintenance/vehicle-maintenance.component';
import { OfficeExpenseComponent } from './office-expense/office-expense.component';
import { OurownVehicleExpenseComponent } from './ourown-vehicle-expense/ourown-vehicle-expense.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OfficeExpanceListComponent } from './office-expance-list/office-expance-list.component';
import { sharedModule } from 'src/app/shared/myshared.module';
import { DataTablesModule } from 'angular-datatables';
import { ExpanceLayoutComponent } from './expance-layout/expance-layout.component';
import { VehicleMaintainanceListComponent } from './vehicle-maintainance-list/vehicle-maintainance-list.component';
import { OwnVehicleExpenceListComponent } from './own-vehicle-expence-list/own-vehicle-expence-list.component';
import { EditOfficeExpenseComponent } from './edit-office-expense/edit-office-expense.component';
import { ManageVehicleModule } from '../manage-vehicle/manage-vehicle.module';
import { EditVehicleMaintenanceComponent } from './edit-vehicle-maintenance/edit-vehicle-maintenance.component';
import { ViewExpanceComponent } from './view-expance/view-expance.component';
import { ViewVehicleMaintanceComponent } from './view-vehicle-maintance/view-vehicle-maintance.component';

@NgModule({
  declarations: [AddExpenseLogComponent, HireVehicleFlatComponent, HireVehicleMonthlyComponent, VehicleMaintenanceComponent, OfficeExpenseComponent, OurownVehicleExpenseComponent, OfficeExpanceListComponent, ExpanceLayoutComponent,VehicleMaintainanceListComponent, OwnVehicleExpenceListComponent, EditOfficeExpenseComponent, EditVehicleMaintenanceComponent, ViewExpanceComponent, ViewVehicleMaintanceComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    ManageExpenseLogRoutingModule,
    sharedModule,
    ManageVehicleModule
  ]
})
export class ManageExpenseLogModule { }
