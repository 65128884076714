<div class="container-fluid">
    <div class="row manageaccount mt-3">
        <div class="col-md-6 my-auto    ">
            <nav aria-label="breadcrumb mb-0">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item" routerLink="/superAdmin/manageExpenseLog">Manage Expense Log</li>
                    <li class="breadcrumb-item active" aria-current="page">Add Hire Vichele Monthly</li>

                </ol>
            </nav>
        </div>
        <div class="col-xl-6 col-12 col-sm-12 col-md-12">
            <ul class="addaccount">
                <li class="my-auto"><button (click)="backTo()" class="btn btn-primary btn-sm text-white px-4"><i
                            class="fas fa-arrow-left"></i> Back</button></li>
            </ul>
        </div>

    </div>

    <div class="row">
        <div class="col-xl-12">
            <form [formGroup]="addHireVehicleMonthlyForm" (ngSubmit)="addHireVehicleMonthly()"
                enctype="multipart/form-data">
                <div class="row account mb-3">

                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Supervisor Name</label>
                            <select id="supervisornamectrl" formControlName="supervisorname" placeholder="Select Status"
                                class="form-control shadow-none">
                                <option disabled value="">--Select Supervisor Name--</option>
                                <option *ngFor="let s of superVisor" value="{{s.full_name}}">{{s.full_name}}</option>

                            </select>

                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Category</label>
                            <select id="expenseCategoryctrl" formControlName="expenseCategory"
                                class="form-control shadow-none">
                                <option disabled value="">--Select Expense Category--</option>
                                <option value="Hire Vehicle Monthly">Hire Vehicle Monthly</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Company Name</label>
                            <select id="companynamectrl" formControlName="companyname" class="form-control shadow-none">
                                <option disabled value="">--Select Company Name--</option>
                                <option *ngFor="let c of company" value="{{c.company_name}}">{{c.company_name}}</option>

                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">
                            <label for="">Vehicle Type</label>
                            <select id="vehicleTypectrl" formControlName="vehicleType" placeholder="Select Vehicle Type"
                                class="form-control shadow-none">
                                <option disabled value="">--Select Vehicle Type--</option>
                                <option *ngFor="let c of vehicletypes" value="{{c.vehicletype_name}}">
                                    {{c.vehicletype_name}}</option>

                            </select>

                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Vehicle Number</label>
                            <input type="text" id="vehicleNumberctrl" class="form-control shadow-none"
                                placeholder="Enter Vehicle Number" formControlName="vehicleNumber">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Diesel or Petrol</label>
                            <input type="text" id="dieselctrl" class="form-control shadow-none" placeholder="Enter Rate "
                                formControlName="diesel">
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">KM</label>
                            <input type="text" id="KMctrl" class="form-control shadow-none" placeholder="Enter KM "
                                formControlName="KM">
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Head</label>
                            <input type="text" id="expenseHeadctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Head " formControlName="expenseHead">
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Detention</label>
                            <input type="text" id="detentionctrl" class="form-control shadow-none"
                                placeholder="Enter Detention " formControlName="detention">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Police</label>
                            <input type="text" id="policectrl" class="form-control shadow-none"
                                placeholder="Enter Police " formControlName="police">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Toll Tax</label>
                            <input type="text" id="tollTaxctrl" class="form-control shadow-none"
                                placeholder="Enter Toll Tax " formControlName="tollTax">
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Labour Amount</label>
                            <input type="text" id="labourAmountctrl" class="form-control shadow-none"
                                placeholder="Enter Labour Amount " formControlName="labourAmount">
                        </div>
                    </div>



                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Rate</label>
                            <input type="text" id="ratectrl" class="form-control shadow-none" placeholder="Enter Rate "
                                formControlName="rate">
                        </div>
                    </div>


                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Amount</label>
                            <input type="text" id="expenseAmountctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Amount " formControlName="expenseAmount">
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-6 col-sm-6">
                        <div class="form-group accountsinp  mb-3">

                            <label for="">Expense Description</label>
                            <input type="text" id="expenseDescriptionctrl" class="form-control shadow-none"
                                placeholder="Enter Expense Description " formControlName="expenseDescription">
                        </div>
                    </div>







                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="form-group text-center accountsinp  mb-3">
                            <p>LR Receipt1 </p>

                            <input type="file" accept="image/*" (change)="fileChangeEventattachReceipt1($event)"
                                id="LRReceipt1ctrl" class="form-control shadow-none" formControlName="LRReceipt1">

                        </div>

                    </div>

                    <div class="col-md-12">

                        <div class="row justify-content-center mt-2">
                            <div class="col-lg-2 col-sm-3 col-md-3 col-12 text-center">

                                <button class="btn btn-primary text-white px-5" type="button" (click)="resetForm()"
                                    mat-raised-button>Reset</button>
                            </div>
                            <div class="col-lg-2 col-sm-3 col-md-3 col-12 text-center">

                                <button class="btn btn-primary text-white px-5" type="submit" mat-raised-button>Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>