import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageVehicleRoutingModule } from './manage-vehicle-routing.module';
import { VechileListComponent } from './vechile-list/vechile-list.component';
import { AddVechileComponent } from './add-vechile/add-vechile.component';
import { EditVechileComponent } from './edit-vechile/edit-vechile.component';
import { DataTablesModule } from "angular-datatables";
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { sharedModule } from 'src/app/shared/myshared.module';
import { ImagePreviewComponent } from './image-preview/image-preview.component';

@NgModule({
  declarations: [VechileListComponent, AddVechileComponent, EditVechileComponent, ImagePreviewComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DataTablesModule,
    MatTooltipModule,
    ManageVehicleRoutingModule,
    sharedModule
  ],
  exports:[ImagePreviewComponent]
})
export class ManageVehicleModule { }
